<template>
  <div>
    <h1>
      404
    </h1>
    <p>
      {{$t('notFound')}}
    </p>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
h1 {
  text-align: center;
  color: #1b75cc;
  font-size: 17rem;

}
p {

  text-align: center;
  color: #1b75cc;
  font-size: 4rem;
}
</style>